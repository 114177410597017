import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import VideoComponent from "../molecules/VideoAutoplay";
import Container from "../atoms/Container";
import Button from "../atoms/Button";
import ChevronRight from "../atoms/icons/ChevronRight";
import { Fade } from "react-reveal";
import RouterLink from "../atoms/Link";
import Logo from "../atoms/icons/Logo";

const StyledHeader = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.isHomePage ? "30px" : "35px")};
  min-height: ${(props) => {
    if (props.isHomePage) {
      return "470px";
    }
    if (props.subText) {
      return "400px";
    }
    return "300px";
  }};

  @media only screen and (min-width: 576px) {
    margin-bottom: ${(props) => (props.isHomePage ? "0" : "50px")};
    min-height: ${(props) => {
      if (props.isHomePage) {
        return "550px";
      }
      if (props.subText) {
        return "500px";
      }
      return "370px";
    }};
  }

  @media only screen and (min-width: 991px) {
    min-height: ${(props) => {
      if (props.isHomePage) {
        return "550px";
      }
      if (props.subText) {
        return "520px";
      }
      return "370px";
    }};
  }

  @media only screen and (min-width: 1200px) {
    min-height: ${(props) => {
      if (props.isHomePage) {
        return "480px";
      }
      if (props.subText) {
        return "480px";
      }
      return "520px";
    }};
  }
`;

const StyledTitle = styled.h2`
  position: relative;
  display: inline-block;
  color: ${color.body.base};
  font-family: ${font.family.head};
  font-size: ${(props) => (props.isHomePage ? "22px" : "22px")};
  line-height: 1.8rem;
  font-weight: 700;
  background-color: ${color.gray.black};
  padding: ${(props) => {
    if (props.hasStripes) {
      return "15px 20px 15px 15px;";
    }

    if (!props.isHomePage) {
      return "15px;";
    }
    return "15px;";
  }};
  text-transform: uppercase;

  &:before {
    position: absolute;
    display: block;
    content: "";
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${color.primary.base};
    z-index: 1;
  }

  @media only screen and (min-width: 576px) {
    font-size: ${(props) => (props.isHomePage ? "42px" : "32px")};
    line-height: 1.8rem;
    padding: ${(props) => {
      if (props.hasStripes) {
        return "20px 30px 20px 20px;";
      }

      if (!props.isHomePage) {
        return "15px;";
      }
      return "20px;";
    }};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${(props) => (props.isHomePage ? "52px" : "42px")};
    line-height: 2.2rem;
  }
`;

const StyledTitleWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => {
    if (props.subText && props.isHomePage) {
      return "160px";
    }
    if (props.subText || props.hasLogos) {
      return "90px";
    }
    return "-10px";
  }};
  transform: none;
  left: 0;
  padding-top: 0;
  z-index: 1;

  @media only screen and (min-width: 785px) {
    position: ${(props) => (props.hasCenteredTitle ? "absolute" : "relative")};
    top: ${(props) => (props.hasCenteredTitle ? "50%" : "auto")};
    transform: ${(props) =>
      props.hasCenteredTitle ? "translateY(-50%)" : "none"};
    bottom: auto;
    left: ${(props) => (props.hasCenteredTitle ? "0" : "auto")};
    padding-top: ${(props) => (props.hasCenteredTitle ? "0" : "30px")};
    margin-bottom: 30px;
  }

  div {
    display: block;

    &:first-child {
      .title-line {
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 576px) {
    div {
      &:first-child {
        .title-line {
          margin-bottom: 15px;
          width: auto;
        }
      }
    }
  }

  @media only screen and (min-width: 1320px) {
    margin-left: -60px;
    padding-top: ${(props) => (props.hasCenteredTitle ? "0" : "60px")};
  }
`;

const HeaderFadeWrapper = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  right: -20px;
  left: -20px;
  pointer-events: none;

  @media only screen and (min-width: 785px) {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
  }
`;

const StyledLogoWrapper = styled.div`
  display: flex !important;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;

  svg {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1 / 1;
    fill: white;
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: 1200px) {
    svg {
      fill: ${color.primary.base};
      width: 3.5rem;
      height: 3.5rem;
    }

  }

`;

const StyledMediaWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 280px;
  z-index: 0;
  overflow: hidden;

  @media only screen and (min-width: 576px) {
    max-height: 400px;
  }

  @media only screen and (min-width: 1200px) {
    position: absolute;
    right: -160px;
    top: 0;
    width: 910px;
    height: 512px;
    max-height: none;
  }

  &:before {
    transition: all 0.2s ease-in-out;
    position: absolute;
    transform: scale(1);
    content: "";
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1;

    @media only screen and (min-width: 785px) {
      height: 100%;
    }
  }

  &:after {
    @media only screen and (max-width: 1200px) {
      position: absolute;
      content: "";
      inset: 0;
      z-index: 1;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
      pointer-events: none;
    }
  }

  &:before {
    background: linear-gradient(
      to bottom,
      rgba(10, 11, 9, 0) 0%,
      rgba(10, 11, 9, 0.35) 100%
    );
    opacity: ${(props) => (props.hideOverlay ? "0" : "1")};
  }

  video,
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  video {
    filter: grayscale(35%);
  }
`;

const StyledImage = styled.img``;

const StyledStripesWrapper = styled.span`
  margin-left: 20px;
`;

const StyledVideoComponent = styled(VideoComponent)`
  filter: grayscale(100%);
`;

const StyledStripe = styled.img`
  display: inline-block;
  width: 20px;
  height: auto;
  margin: 0 -5px;

  @media only screen and (min-width: 576px) {
    width: 25px;
  }

  @media only screen and (min-width: 768px) {
    width: 35px;
  }
`;

const StyledTextWrapper = styled.div``;

const StyledSubTextWrapper = styled.h4`
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  max-width: 380px;
  margin-bottom: 30px;

  @media only screen and (min-width: 785px) {
    font-size: 22px;
    max-width: 375px;
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  position: absolute;
  fill: ${color.body.base};
  width: 9px;
  height: 14px;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: -10px;
  right: 20px;
  width: 100%;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: column;
    justify-content: normal;
    margin-bottom: 20px;
    right: auto;
    left: -20px;
  }
`;

const StyledOuterContentWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.isHomePage ? "-10px" : "-50px")};
  left: ${(props) => (props.isHomePage ? "20px" : "35px")};
  width: 100%;

  @media only screen and (min-width: 576px) {
    bottom: ${(props) => (props.isHomePage ? "0" : "-60px")};
  }
`;

const Header = ({
  className,
  titleLineFirst,
  titleLineSecond,
  hasStripes,
  imageUrl,
  videoUrl,
  subText,
  buttonText,
  onClick,
  isHomePage,
  children,
  hasCenteredTitle,
  href,
  picture,
  hideOverlay,
  logos,
}) => (
  <Container>
    <StyledHeader
      className={className}
      isHomePage={isHomePage}
      subText={subText}
    >
      <StyledTextWrapper>
        <StyledTitleWrapper
          hasCenteredTitle={hasCenteredTitle}
          subText={subText}
          isHomePage={isHomePage}
          hasLogos={!!logos?.length}
        >
          <Fade left distance="20px">
            <>
              <div>
                <StyledTitle
                  hasStripes={hasStripes}
                  className="title-line title-line--first"
                  isHomePage={isHomePage}
                >
                  {titleLineFirst}
                  {hasStripes === true && (
                    <StyledStripesWrapper>
                      <StyledStripe
                        src={require("../assets/svg/dij-slash.svg")}
                      />
                      <StyledStripe
                        src={require("../assets/svg/dij-slash.svg")}
                      />
                    </StyledStripesWrapper>
                  )}
                </StyledTitle>
              </div>
              {titleLineSecond && (
                <div>
                  <StyledTitle
                    className="title-line title-line--second"
                    isHomePage={isHomePage}
                    hasStripes={hasStripes}
                  >
                    {titleLineSecond}
                  </StyledTitle>
                </div>
              )}
              {!!logos?.length && (
                <StyledLogoWrapper>
                  {logos.map((Logo, index) => <Logo key={index} />)}
                </StyledLogoWrapper>
              )}
            </>
          </Fade>
        </StyledTitleWrapper>
        <StyledOuterContentWrapper isHomePage={isHomePage}>
          <Fade left distance="20px" delay={100}>
            <HeaderContentWrapper>
              {subText && (
                <StyledSubTextWrapper>{subText}</StyledSubTextWrapper>
              )}
              {buttonText && (
                <RouterLink href={href}>
                  <Button icon={<StyledChevronRight />} onClick={onClick}>
                    {buttonText}
                  </Button>
                </RouterLink>
              )}
            </HeaderContentWrapper>
          </Fade>
        </StyledOuterContentWrapper>
      </StyledTextWrapper>
      <HeaderFadeWrapper>
        <Fade right distance="20px" delay={200}>
          <StyledMediaWrapper hideOverlay={hideOverlay}>
            {imageUrl && <StyledImage src={imageUrl} />}
            {videoUrl && <StyledVideoComponent videoUrl={videoUrl} />}
            {picture}
          </StyledMediaWrapper>
        </Fade>
      </HeaderFadeWrapper>
      {children}
    </StyledHeader>
  </Container>
);

export default Header;
