import * as React from "react";
import { Component } from "react";

class VideoComponent extends Component {
  // videoContainer: HTMLDivElement;
  componentDidMount() {
    const video = document.createElement("video");
    video.autoplay = true;
    video.loop = true;
    video.muted = true; // fixes autoplay in chrome
    video.setAttribute("playsinline", "true"); // fixes autoplay in webkit (ie. mobile safari)

    const source = document.createElement("source");
    source.src = this.props.videoUrl;
    source.type = "video/mp4";
    video.appendChild(source);

    this.videoContainer.appendChild(video);
  }
  render() {
    return (
      <div
        ref={(ref) => {
          this.videoContainer = ref;
        }}
      />
    );
  }
}

export default VideoComponent;
